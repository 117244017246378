import React from "react";
import Grid from '@mui/joy/Grid';
import CommentSidebar from "../Comment/CommentSidebar";
import Sheet from "@mui/joy/Sheet";
import {CardOverflow, Stack, TabList, TabPanel, Tabs} from "@mui/joy";
import Tab, {tabClasses} from '@mui/joy/Tab';

import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";

class CommonDetail extends React.Component {
    render() {
        return (
            <Grid container spacing={2} sx={{flexGrow: 1, height: '100%'}}>
                <Grid md={8} xs={12}>
                    <Sheet
                        variant="plain"
                        color="neutral"
                        sx={{
                                p: 2,
                                height: '100%',
                                width: '100%',
                                alignContent: 'space-between',
                                display: 'flex',
                                flexWrap: 'wrap',
                                flexDirection: 'column',
                                borderRadius: 'md',
                        }}
                    >
                        <Card
                            variant="soft"
                            sx={{
                                width: '100%'
                            }}
                            orientation="horizontal"
                        >
                            {
                                this.props.header.leftOverflow?
                                    <CardOverflow
                                        variant='soft'
                                        color={this.props.header.leftOverflow.color}
                                        sx={{
                                            px: 0.2,
                                            writingMode: 'vertical-rl',
                                            textAlign: 'center',
                                            fontSize: 'xs',
                                            fontWeight: 'xl',
                                            letterSpacing: '1px',
                                            textTransform: 'uppercase',
                                            borderLeft: '1px solid',
                                            borderColor: 'divider',
                                        }}
                                    >
                                        {
                                            this.props.header.leftOverflow.icon?
                                                this.props.header.leftOverflow.icon:null
                                        }
                                        {this.props.header.leftOverflow.title}
                                    </CardOverflow>:
                                    null
                            }
                            <CardContent>
                                <this.props.header.component
                                    {...this.props.header.componentProps}
                                />
                            </CardContent>
                            {
                                this.props.header.rightOverflow?
                                    <CardOverflow
                                        variant='soft'
                                        color={this.props.header.rightOverflow.color}
                                        sx={{
                                            px: 0.2,
                                            // writingMode: 'vertical-rl',
                                            textAlign: 'center',
                                            fontSize: 'xs',
                                            fontWeight: 'xl',
                                            letterSpacing: '1px',
                                            textTransform: 'uppercase',
                                            borderLeft: '1px solid',
                                            borderColor: 'divider',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Box>
                                            {
                                                this.props.header.rightOverflow.icon?
                                                    this.props.header.rightOverflow.icon:null
                                            }
                                        </Box>
                                        <Typography
                                            level={'body-sm'}
                                        >
                                            {this.props.header.rightOverflow.title}
                                        </Typography>
                                        <Box>
                                            {
                                                this.props.header.rightOverflow.component?
                                                    this.props.header.rightOverflow.component:null
                                            }
                                        </Box>
                                    </CardOverflow>:
                                    null
                            }
                        </Card>
                        <Tabs
                            orientation={'horizontal'}
                            aria-label='Placement indicator tabs'
                            defaultValue={this.props.defaultTabValue}
                            sx={{
                                gridColumn: '1/-1',
                                flexDirection: 'column',
                                borderRadius: 'var(--joy-radius-md);',
                                mt: 1,
                                height: 'calc(100vh - 250px)',
                                width: '100%',
                            }}
                        >
                            <TabList
                                disableUnderline
                                sx={{
                                    pr: 1,
                                    pl: 1,
                                    pt: 1,
                                    pb: 2,
                                    gap: 0.5,
                                    borderRadius: 'md',
                                    bgcolor: 'background.level1',
                                    [`& .${tabClasses.root}[aria-selected="true"]`]: {
                                        boxShadow: 'sm',
                                        bgcolor: 'background.surface',
                                    },
                                    display: 'block',
                                }}
                            >
                                {this.props.tabComponents.map(
                                    each => {
                                        return (
                                            <Tab
                                                disableIndicator
                                                orientation="vertical"
                                                value={each.value}
                                                sx={{
                                                    borderRadius: 'md',
                                                    flex: 'none',
                                                    display: 'inline-block',
                                                    scrollSnapAlign: 'start',
                                                    verticalAlign: 'middle',
                                                    pl: 1,
                                                    pr: 1,
                                                }}
                                            >
                                                <Stack

                                                >
                                                    <ListItemDecorator
                                                        sx={{
                                                            verticalAlign: 'middle',
                                                        }}
                                                    >
                                                        {each.icon}
                                                    </ListItemDecorator>
                                                    <Typography
                                                        level={'body-sm'}
                                                        sx={{
                                                            display: 'inline',
                                                            verticalAlign: 'middle',
                                                        }}
                                                    >
                                                        {each.title}
                                                    </Typography>
                                                </Stack>


                                            </Tab>
                                        )
                                    }
                                )}
                            </TabList>
                            {this.props.tabComponents.map(
                                each => {
                                    return (
                                        <TabPanel
                                            value={each.value}
                                            variant={'soft'}
                                            sx={{
                                                mt: 1,
                                                borderRadius: 'md',
                                                overflow: 'auto',
                                                width: '100%'
                                            }}
                                        >
                                            {each.component}
                                        </TabPanel>
                                    )
                                }
                            )}
                        </Tabs>
                    </Sheet>
                </Grid>
                <Grid
                    md={4}
                    xs={12}
                    sx={{
                            height: '100%',
                    }}
                >
                    <CommentSidebar
                        appName={this.props.appName}
                        appId={this.props.appId}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default CommonDetail;
