import React from 'react';
import CommonSidebar from "../Shared/CommonSidebar";

//icons
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ForkLeftIcon from '@mui/icons-material/ForkLeft';
import KeyIcon from '@mui/icons-material/Key';
import LightbulbIcon from '@mui/icons-material/Lightbulb';


class AdminSidebar extends React.Component {
    render() {
        return(
            <CommonSidebar
                groups={[
                    {
                        title: 'Администрирование',
                        items: [
                            {
                                title: 'Бизнес-процессы',
                                link: '/admin/delivery/',
                                icon: <AccountTreeIcon />,
                            },
                            {
                                title: 'Маршруты',
                                link: '/admin/route/',
                                icon: <ForkLeftIcon />,
                            },
                            {
                                title: 'Права',
                                link: '/admin/permission/',
                                icon: <KeyIcon />,
                            },
                            {
                                title: 'MindMap',
                                link: '/admin/ideas/',
                                icon: <LightbulbIcon />,
                            }
                        ],
                    }
                    ]
                }
            />
        )
    }
}

export default AdminSidebar;