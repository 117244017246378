import React from 'react';
import {createBrowserRouter} from 'react-router-dom';
import './index.css';

// components
import Root from './components/Root/Root';
import Error404 from './components/Errors/Error404';
import OpportunityDetail from './components/Opportunity/OpportunityDetail';
import Login from './components/Login/Login';
import HomePage from './components/HomePage/HomePage';
import DeliveryList from './components/Delivery/DeliveryList';
import NotificationList from "./components/Notification/NotificationList";
import NotificationDetail from "./components/Notification/NotificationDetail";
import TaskMyBoard from "./components/Task/TaskMyBoard";
import OpportunityReport from "./components/Opportunity/OpportunityReport";
import AccountDetail from "./components/Account/AccountDetail";
import AccountList from "./components/Account/AccountList";
import ContactList from "./components/Contact/ContactList";
import KpiMyList from "./components/Kpi/KpiMyList";
import KpiSubordinateList from "./components/Kpi/KpiSubordinateList";
import PermissionReport from "./components/Permission/PermissionReport";
import FeatureRequestIdeaList from "./components/FeatureRequest/FeatureRequestIdeaList";
import FeatureRequestBugList from "./components/FeatureRequest/FeatureRequestBugList";
import FeatureRequestTechList from "./components/FeatureRequest/FeatureRequestTechList";
import FeatureRequestDetail from "./components/FeatureRequest/FeatureRequestDetail";
import Messenger from "./components/Messenger/Messenger";
import ContactDetail from "./components/Contact/ContactDetail";
import TaskOwnerBoard from "./components/Task/TaskOwnerBoard";
import TaskMyCompleted from "./components/Task/TaskMyCompleted";
import TaskOwnerCompleted from "./components/Task/TaskOwnerCompleted";
import DeliverySubordinateLists from "./components/Delivery/DeliverySubordinateLists";
import EmployeeBonusList from "./components/Employee/EmployeeBonusList";
import DeliveryAdmin from "./components/Admin/DeliveryAdmin";
import RouteAdmin from "./components/Admin/RouteAdmin";
import LeadIncomingList from "./components/Lead/LeadIncomingList";
import LeadOutgoingList from "./components/Lead/LeadOutgoingList";
import LeadDetail from "./components/Lead/LeadDetail";
import TaskMyCalendar from "./components/Task/TaskMyCalendar";
import TaskSubscriberCalendar from "./components/Task/TaskSubscriberCalendar";
import RouteList from "./components/Route/RouteList";
import RouteSubordinateLists from "./components/Route/RouteSubordinateLists";
import VacationList from "./components/Vacation/VacationList";
import Profile from "./components/Profile/Profile";
import IdeaMap from "./components/Idea/IdeaMap";


const router = createBrowserRouter(
    [
        {
            path: '/login/',
            element: <Login/>,
            errorElement: <Error404/>,
        },
        {
            path: '/',
            element: <Root/>,
            errorElement: <Error404/>,
            children: [
                {
                    path: '',
                    element: <HomePage/>,
                },
                {
                    path: 'profile',
                    children: [
                        {
                            path: '',
                            element: <Profile />,
                        },
                    ]
                },
                {
                    path: 'notification/',
                    children: [
                        {
                            path: '',
                            element: <NotificationList/>,
                        },
                        {
                            path: 'page/:pageNumber',
                            element: <NotificationList/>,
                        },
                        {
                            path: 'detail/:notificationId',
                            element: <NotificationDetail/>,
                        },
                    ],
                },
                {
                    path: 'account',
                    children: [
                        {
                            path: '',
                            element: <AccountList />,
                        },
                        {
                            path: 'page/:pageNumber',
                            element: <AccountList />,
                        },
                        {
                            path: 'detail/:accountId',
                            element: <AccountDetail />,
                        },
                    ]
                },
                {
                    path: 'contact',
                    children: [
                        {
                            path: '',
                            element: <ContactList />,
                        },
                        {
                            path: 'page/:pageNumber',
                            element: <ContactList />,
                        },
                        {
                            path: 'detail/:contactId',
                            element: <ContactDetail />,
                        },
                    ]
                },
                {
                    path: 'opportunity',
                    children: [
                        {
                            path: '',
                            element: <OpportunityReport />,
                        },
                        {
                            path: 'detail/:opportunityId',
                            element: <OpportunityDetail />,
                        },
                    ]
                },
                {
                    path: 'kpi',
                    children: [
                        {
                            path: '',
                            element: <KpiMyList />,
                        },
                        {
                            path: 'subordinate',
                            element: <KpiSubordinateList />,
                        },

                    ]
                },
                {
                    path: 'delivery/',
                    children: [
                        {
                            path: '',
                            element: <DeliveryList/>,
                        },
                        {
                            path: 'subordinate',
                            element: <DeliverySubordinateLists/>
                        }
                    ],
                },
                {
                    path: 'route/',
                    children: [
                        {
                            path: '',
                            element: <RouteList/>,
                        },
                        {
                            path: 'subordinate',
                            element: <RouteSubordinateLists/>
                        }
                    ],
                },
                {
                    path: 'task',
                    children: [
                        {
                            path: '',
                            element: <TaskMyBoard />,
                        },
                        {
                            path: 'mycalendar',
                            element: <TaskMyCalendar />,
                        },
                        {
                            path: 'calendar/:subscriberId',
                            element: <TaskSubscriberCalendar />,
                        },
                        {
                            path: 'owner',
                            element: <TaskOwnerBoard />,
                        },
                        {
                            path: 'completed',
                            element: <TaskMyCompleted />
                        },
                        {
                            path: 'completed/page/:pageNumber',
                            element: <TaskMyCompleted />,
                        },
                        {
                            path: 'ownercompleted',
                            element: <TaskOwnerCompleted />
                        },
                        {
                            path: 'ownercompleted/page/:pageNumber',
                            element: <TaskOwnerCompleted />,
                        },
                    ]
                },
                {
                    path: 'admin',
                    children: [
                        {
                            path: 'permission',
                            element: <PermissionReport />,
                        },
                        {
                            path: 'delivery',
                            element: <DeliveryAdmin />,
                        },
                        {
                            path: 'route',
                            element: <RouteAdmin />,
                        },
                        {
                            path: 'ideas',
                            element: <IdeaMap />,
                        },
                    ]
                },

                {
                    path: 'feature_request',
                    children: [
                        {
                            path: '',
                            element: <FeatureRequestIdeaList />,
                        },
                        {
                            path: 'detail/:featureRequestId',
                            element: <FeatureRequestDetail />,
                        },
                        {
                            path: 'idea',
                            element: <FeatureRequestIdeaList />,
                        },
                        {
                            path: 'idea/page/:pageNumber',
                            element: <FeatureRequestIdeaList/>,
                        },
                        {
                            path: 'bug',
                            element: <FeatureRequestBugList />,
                        },
                        {
                            path: 'bug/page/:pageNumber',
                            element: <FeatureRequestBugList/>,
                        },
                        {
                            path: 'tech',
                            element: <FeatureRequestTechList />,
                        },
                        {
                            path: 'tech/page/:pageNumber',
                            element: <FeatureRequestTechList/>,
                        },

                    ]
                },
                {
                    path: 'messenger',
                    children: [
                        {
                            path: '',
                            element: <Messenger />,
                        },
                    ]
                },
                {
                    path: 'operations',
                    children: [
                        {
                            path: 'bonus',
                            element: <EmployeeBonusList />,
                        },
                        {
                            path: 'vacation',
                            element: <VacationList />,
                        },
                    ]
                },
                {
                    path: 'lead',
                    children: [
                        {
                            path: 'detail/:leadId',
                            element: <LeadDetail />,
                        },
                        {
                            path: 'incoming',
                            element: <LeadIncomingList />,
                        },
                        {
                            path: 'incoming/page/:pageNumber',
                            element: <LeadIncomingList/>,
                        },
                        {
                            path: 'outgoing',
                            element: <LeadOutgoingList />,
                        },
                        {
                            path: 'outgoing/page/:pageNumber',
                            element: <LeadOutgoingList/>,
                        },
                    ]
                }
            ],
        },
    ]
);

export default router;
